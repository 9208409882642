.App {
  text-align: center;
  margin: 0;
  background: #0a0a0a;
  font-family: 'Roboto', sans-serif;
}

@media only screen and (max-width: 45em) {
  .landing {
  }

  .landingContainer {
    display: flex !important;
    flex-direction: column !important;
    height: auto;
    margin: 3vh auto !important;
    /* width: 90vw !important; */
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    row-gap: 20px;
  }

  .projectsContainer {
    width: 90vw !important;
  }
  .servicesContainer {
    width: 90vw !important;
  }
  .contactContainer {
    width: 90vw !important;
  }

  .portrait {
   margin: auto !important;
   width: 60vw !important;
  }

  .text {
    width: 60vw !important;
    color: white;
    font-size: 1rem !important;
    padding-top: 0 !important;
  }
  
  .pdfButton {
    min-width: 60vw !important;
  }
  
  .top-btn-style {
    height: 60px !important;
    width: 60px !important;
  }

  .muvn {
    width: 50vw !important;
    padding: 30px !important;
  }
  .mucaro {
    width: 42vw !important;
  }
  .kk {
    width: 22vw !important;
  }
  .icons {
    margin: 35px !important;
  }
 }

.link {
  text-decoration: none;
  display: inline;
  padding: 20px 20px;
  font-weight: 700;
  font-size: large;
  cursor: pointer;
}

.link::before {
  border-bottom: none;
}

.link::after{
  border-bottom: 5px solid #04CCFBFF;
}

.landing {
  height:90vh;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.landingContainer {
  display: grid;
  grid-area: auto;
  /* flex: auto; */
  justify-content: center;
  align-items: center;
  margin: 3vh auto;
  width: 90vw;
  grid-template-columns: 1fr 3fr;
  column-gap: 40px;
  row-gap: 40px;;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #141414FF;
  border-radius: 10px;
  padding: 25px;
}

.portrait {
  /* height: 40vh; */
  width: 20vw;
  border-radius: 10px;
  object-fit: contain;
  /* grid-row: 1/3;
  grid-column: 1/2; */
}

.text {
  min-width: 350px !important;
  text-align: left;
  /* margin: 20vh auto; */
  padding-top:70px;
  /* background-color: pink; */
  /* font-weight:lighter !important; */
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

button {
  display:inline;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0;
  width: 25vw;
  height: 5vh;
  border-radius: 10px;
  outline: none;
  border: none;
}
.send {
  margin-top: 20px;
}

input {
  height: 5vh;
  width: 25vw;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 10px;
}

textarea {
  min-width: 25vw;
  border-radius: 10px;
  outline: none;
  border: none;
  resize: vertical;
  min-height: 20vh;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
}

input::placeholder, textarea::placeholder {
  font-family: 'Roboto', sans-serif;
}

.services {
  height: 90vh !important;

  position: relative;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.projects {
  height: 90vh !important;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 8vh auto;
}
.contact {
  height: 90vh !important;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 8vh auto;
}

.servicesContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  width: 90vw;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:#141414FF;
  border-radius: 10px;
  padding: 25px
}

.projectsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2vh auto;
  width: 90vw;
  row-gap: 20px;
  column-gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:#141414FF;
  border-radius: 10px;
  padding: 25px
}

.contactContainer {
  /* display: grid;
  grid-template-columns: 1fr; */
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  width: 90vw;
  /* height: 100%; */
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:#141414FF;
  border-radius: 10px;
  padding: 25px
}

.resume {
  height:100vh;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.resumeContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:#141414FF;
  border-radius: 10px;
  padding: 25px
}

.header {
  justify-content: center;
  align-items: center;
  margin: auto;
  position:fixed;
  padding: 10px;
  width: 100%;
  display:flex;
  top: 0;
  background: #0a0a0a;
  opacity: 95%;
  z-index: 9999;
}
#serviceslink:hover, #projectslink:hover, #contactlink:hover {
  border-bottom: solid 5px #04CCFBFF ;
}

.header p {
  font-size: 16px;
}

.services {
  height:100vh;
}

.projects {
  height:100vh;
}

.contact {
  height:100vh;
}

.pdfButton {
  background-color: #04CCFBFF !important;
  width: 20vw;
  border-radius: 10px;
  padding:15px;
  text-decoration: none;
  color: whitesmoke;
  justify-content: center;
  align-items: center;
  display: flex;
}

.box {
  color: #04CCFBFF;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}
.box1 {
  color: #04CCFBFF;
  border: solid 1px #04CCFBFF;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.icons {
  margin: 80px;
  color: whitesmoke;
}
.icons:hover {
  color: #04CCFBFF;
}
.icons:active {
  color: #04CCFBFF;
}
.icons:focus {
  color: #04CCFBFF;
}

/*scroll button */
.top-btn-position {
  position: fixed;
  bottom: 15px;
  right: 20px;
}

.top-btn-style {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #333;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

.top-btn-style:hover {
  animation: none;
  background-color: #04CCFBFF;
  color: #fff;
  outline: none;
  border: none;
}


/* @import url('https://fonts.googleapis.com/css?family=Montserrat'); */

* {
margin: 0;
padding: 0;
box-sizing: border-box;
}
/* body {
font-family: 'Montserrat', sans-serif;
background-color: #000;
color: #eee;
} */

.muvn {
width: 20vw;
padding: 20px;
}
.muvn:hover {
transition: all .2s ease-in-out;
transform: scale(1.1);
}
.mucaro {
width: 16vw;
padding: 0;
}
.mucaro:hover {
transition: all .2s ease-in-out;
transform: scale(1.1);
}
.kk {
width: 8vw;
 padding: 10px;
  filter: invert();
}
.kk:hover {
transition: all .2s ease-in-out;
transform: scale(1.1);
}

.footer {
margin: 8vh auto;
padding: 20px;
/* background: black; */
/* position: absolute; */
bottom: 0;
width: 100%;
}